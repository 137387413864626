/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import entetep4 from "../images/entetep4.png";
import com1 from "../images/com/1.jpg";
import com2 from "../images/com/2.jpg";
import com3 from "../images/com/3.jpg";
import com4 from "../images/com/4.jpg";
import com5 from "../images/com/5.jpg";
import com6 from "../images/com/6.jpg";
import logoaqs from "../images/logoaqsp.png";
import { equipe } from "../types/equipe";
import { inscriptionEquipe } from "../services/inscriptionEquipeService";

const Reglements: React.FC = () => {
  const [comOr, setComOr] = useState(com3);
  const switchComOr = (): void => {
    setComOr(logoaqs);
  };
  const switchComOrBack = (): void => {
    setComOr(com3);
  };

  return (
    <div className="flex flex-col h-max border-4  m-4 rounded-3xl w-full border-electric-violet-400 object-contain ">
      <div className="z-0 absolute object-contain">
        <img className="  opacity-5 " src={entetep4} alt="logo ULaval" />
      </div>
      <div className="flex justify-center border-2 rounded-2xl m-1 ml-10 mr-10 z-50 hover:bg-electric-violet-700/10 bg-robins-egg-blue-300 text-electric-violet-700 hover:text-electric-violet-600">
        <div className="flex items-center">
          <div>
            <h2 className=" text-2xl font-medium m-2">
              Règlements du tournois
            </h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row  ">
        <div className="flex flex-col items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
          <h1 className="text-white text-2xl">Grandes Lignes</h1>

          <ul className="list-disc text-white">
            <li className="m-2">
              Poids total de 700lbs aucun excès possible.(visez 690lbs + 10lbs
              de débattement)
            </li>
            <li className="m-2">4 Contre 4</li>
            <li className="m-2">2 femmes minimum en tout temps</li>
            <li className="m-2">2 bourses : (PRO : 500$, AMICAL: 200$)</li>
            <li className="m-2">
              1 remplacant inscrit par équipe peut inter-changer avant un set (2
              de 3). Sans limite durant la journée.(Gestion intra-équipe à votre
              discrétion.)
            </li>
            <li className="m-2">
              La pesée sera faite avec une balance électronique à partir de 8h
              le samedi.
            </li>
            <li className="m-2">
              Les pools et le parcours vers la victoire seront définis en
              fonction de la force(expérience) de chaque équipe en consensus
              entre les organisateurs de façon le plus équitable. Ils seront
              affichés à en temps réel et changerons en cours de route selon les
              inscriptions.
            </li>
            <li className="m-2">
              Selon les nombres de concurents, nous procéderons par pool ou par
              ronde éliminatoire. Les informations seront disponibles en temps
              réel sur le site web.
            </li>
            <li className="m-2">
              2 classes seront définies avant les rondes éliminatoires. Une
              bourse pour la classe PRO de 500$ et une bourse pour la classe
              AMATEUR de 200$.
            </li>
          </ul>
        </div>{" "}
        <div className="flex flex-col items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
          <h1 className="text-electric-violet-600 text-2xl">Ensuite</h1>

          <ul className="list-disc text-white">
            <li className="m-2">
              Grande première! nous demanderons votre indulgence !
            </li>
            <li className="m-2">
              Quel ambiance! Continuons tous à entretenir une ambiance familiale
              festive !
            </li>
            <li className="m-2">
              Officiel : 2 juges de lignes, 1 arbrite en chef
            </li>
            <li className="m-2">
              Droit à une seule demande de révision (rappel) pour la journée. La
              demande doit être faite dans les 2 minutes après la tire ou avant
              le début de la prochaine tire.
            </li>
            <li className="m-2">Formule : 2 de 3</li>
            <li className="m-2">Tire au hazard pour le choix de côté</li>
            <li className="m-2">Départ : Coach prêt? / Position / GO!</li>
            <li className="m-2">Formule : 2 de 3</li>
            <li className="m-2">Changement de côté entre chaque essai</li>
            <li className="m-2">2 minutes et 30 secondes entre les tires</li>
            <li className="m-2">
              En cas de litige sur la réglementation les officiels auront plein
              pouvoir de décision.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Reglements;
