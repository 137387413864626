/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
// import { deleteJourneeDb, getFullJournee } from "../services/journeeService";
import { equipe } from "../types/equipe";
import xp1 from "../images/xp1.png";
import xp2 from "../images/xp2.png";
import xp3 from "../images/xp3.png";
import xp4 from "../images/xp4.png";
import xp5 from "../images/xp5.png";
import xp6 from "../images/xp6.png";

type IProps = {
  equipe: equipe;
};

const EquipeJeunes: React.FC<IProps> = ({ equipe }) => {
  const [equip, setEquip] = useState<equipe>(equipe);

  const [XpToTal, setXpTotal] = useState(0);
  const [xpGauge, setXpGauge] = useState(xp3);

  useEffect(() => {
    SetXp();
  }, [equip]);

  useEffect(() => {
    setPicGauge();
  }, [xpGauge]);

  const SetXp = async () => {
    setXpTotal(
      equip.experience1 +
        equip.experience2 +
        equip.experience3 +
        equip.experience4
    );
  };
  const setPicGauge = async () => {
    let GaugeChoice = xp1;
    GaugeChoice = XpToTal < 3 ? xp1 : GaugeChoice;
    GaugeChoice = XpToTal >= 3 && XpToTal < 6 ? xp2 : GaugeChoice;
    GaugeChoice = XpToTal >= 6 && XpToTal < 9 ? xp3 : GaugeChoice;
    GaugeChoice = XpToTal >= 9 && XpToTal < 12 ? xp4 : GaugeChoice;
    GaugeChoice = XpToTal >= 12 && XpToTal < 15 ? xp5 : GaugeChoice;
    GaugeChoice = XpToTal >= 15 && XpToTal <= 16 ? xp6 : GaugeChoice;
    setXpGauge(GaugeChoice);
  };

  return (
    <div className="flex flex-col scale-75  sm:scale-100 text-robins-egg-blue-800 border-robins-egg-blue-900/70 z-10 border-8 rounded-2xl m-2 hover:bg-robins-egg-blue-900/10">
      <div className="flex m-4 text-4xl border-robins-egg-blue-900/50 text-robins-egg-blue-900 rounded-xl p-1 border-2 justify-center">
        {equip.nomEquipe}
      </div>
      <div className="flex flex-col sm:flex-row border-2 border-robins-egg-blue-900/5 rounded-xl">
        {" "}
        <div className="flex  h-full justify-center flex-col border-2 border-robins-egg-blue-900/20 m-1 rounded-xl">
          <div>
            <img className="scale-50" src={xpGauge} alt="logo ULaval" />
          </div>
          <div className="flex items-center justify-center sm:text-4xl">
            <div>{"XP: " + XpToTal}</div>
          </div>
        </div>
        <div className="flex-col w-full border-robins-egg-blue-900/20 m-1 rounded-xl">
          <div className="flex border-2 w-full border-robins-egg-blue-900/20 hover:border-y-robins-egg-blue-900 hover:text-electric-violet-700 rounded-xl justify-between">
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.genre1}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.prenom1 + " "}
                {equip.nom1}
              </div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.age1 + " ans "}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{"XP : " + equip.experience1}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.poids1.toString()[0] + "XX LBS"}
              </div>
            </div>
          </div>
          <div className="flex border-2 w-full border-robins-egg-blue-900/20 hover:border-y-robins-egg-blue-900 hover:text-electric-violet-700 rounded-xl justify-between">
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.genre2}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.prenom2 + " "}
                {equip.nom2}
              </div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.age2 + " ans "}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{"XP : " + equip.experience2}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.poids2.toString()[0] + "XX LBS"}
              </div>
            </div>
          </div>
          <div className="flex border-2 w-full border-robins-egg-blue-900/20 hover:border-y-robins-egg-blue-900 hover:text-electric-violet-700 rounded-xl justify-between">
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.genre3}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.prenom3 + " "}
                {equip.nom3}
              </div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.age3 + " ans "}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{"XP : " + equip.experience3}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.poids3.toString()[0] + "XX LBS"}
              </div>
            </div>
          </div>
          <div className="flex border-2 w-full border-robins-egg-blue-900/20 hover:border-y-robins-egg-blue-900 hover:text-electric-violet-700 rounded-xl justify-between">
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.genre4}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.prenom4 + " "}
                {equip.nom4}
              </div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.age4 + " ans "}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{"XP : " + equip.experience4}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.poids4.toString()[0] + "XX LBS"}
              </div>
            </div>
          </div>
          <div className="flex border-2 w-full border-robins-egg-blue-900/20 hover:border-y-robins-egg-blue-900 hover:text-electric-violet-700 rounded-xl justify-between">
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.genre5}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.prenom5 + " "}
                {equip.nom5}
              </div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{equip.age5 + " ans "}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">{"XP : " + equip.experience5}</div>
            </div>
            <div className="flex flex-col p-2">
              <div className="mt-2">
                {equip.poids5.toString()[0] + "XX LBS"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center m-4 text-7xl text-robins-egg-blue-900">
        {equip.poids1 +
          equip.poids2 +
          equip.poids3 +
          equip.poids4 +
          equip.poids5 +
          " LBS"}
      </div>
    </div>
  );
};

export default EquipeJeunes;
