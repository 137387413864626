/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import Equipe from "./Equipe";
import { v4 as uuidv4 } from "uuid";
import { equipe } from "../types/equipe";
import { getFullEquipe } from "../services/inscriptionEquipeService";
import entetep4 from "../images/entetep4.png";

const EquipeList: React.FC = () => {
  const [equipes, setEquipes] = useState<equipe[]>([]);

  useEffect(() => {
    getEquipe();
  }, []);

  const getEquipe = async () => {
    try {
      const fetchedEquipe: equipe[] = await getFullEquipe();
      for (const equipe in fetchedEquipe) {
        if (
          fetchedEquipe[equipe].poids1 === null ||
          fetchedEquipe[equipe].poids1 === undefined
        ) {
          fetchedEquipe[equipe].poids1 = 0;
        }
        if (
          fetchedEquipe[equipe].poids2 === null ||
          fetchedEquipe[equipe].poids2 === undefined
        ) {
          fetchedEquipe[equipe].poids2 = 0;
        }
        if (
          fetchedEquipe[equipe].poids3 === null ||
          fetchedEquipe[equipe].poids3 === undefined
        ) {
          fetchedEquipe[equipe].poids3 = 0;
        }
        if (
          fetchedEquipe[equipe].poids4 === null ||
          fetchedEquipe[equipe].poids4 === undefined
        ) {
          fetchedEquipe[equipe].poids4 = 0;
        }
        if (
          fetchedEquipe[equipe].poids5 === null ||
          fetchedEquipe[equipe].poids5 === undefined
        ) {
          fetchedEquipe[equipe].poids5 = 0;
        }
      }
      setEquipes(fetchedEquipe);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div className="flex h-max w-full flex-col items-center border-4 rounded-3xl p-1  bg-robins-egg-blue-100">
      <div className="z-0 absolute object-contain">
        <img className="  opacity-5 " src={entetep4} alt="logo ULaval" />
      </div>
      <div className="flex w-5/6 justify-center border-2 p-2 rounded-2xl  z-10 text-4xl font-medium bg-robins-egg-blue-300 text-electric-violet-700 m-1 ">
        <div>
          <h2 className="">les équipes !</h2>
        </div>
      </div>
      <div className="flex flex-col ">
        {equipes.map((equipes: equipe) => (
          <Equipe key={uuidv4()} equipe={equipes} />
        ))}
      </div>
    </div>
  );
};

export default EquipeList;
