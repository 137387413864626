/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import facebook from "../images/facebook.png";

const Contact: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center rounded-2xl opacity-100 w-5/6 border-2 border-electric-violet-500 p-4 m-4">
      <div className="text-2xl text-robins-egg-blue-800 m-8">
        <a href="mailto:info@puissance4.net">
          <h2>INFO@PUISSANCE4.NET</h2>
        </a>
      </div>
      <div className="text-2xl">
        <a href="https://www.facebook.com/D%C3%A9fi-Puissance-4-100328629497519">
          <img className="w-auto" src={facebook} alt="logo ULaval" />
        </a>
      </div>
      <div className="text-2xl text-robins-egg-blue-800 m-8">
        <a href="mhttps://www.puissance4.net">
          <h2>WWW.PUISSANCE4.NET</h2>
        </a>
      </div>
    </div>
  );
};

export default Contact;
