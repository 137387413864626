/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  faHandHoldingUsd,
  faStore,
  faTools,
  faMoneyCheck,
  faClipboardCheck,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import LeftMenuItem from "./LeftMenuItem";
import com1 from "../images/com/1.jpg";
import com2 from "../images/com/2.jpg";
import com3 from "../images/com/3.jpg";
import com4 from "../images/com/4.jpg";
import com5 from "../images/com/5.jpg";
import com6 from "../images/com/6.jpg";
import hbcom from "../images/com/hbcom.png";
import jbcom from "../images/com/jbcom.png";
import menuxpand from "../images/menuxpand.png";
import graincom from "../images/com/graincom.png";
import rivgencom from "../images/com/rivgencom.png";
import pichecom from "../images/com/pichecom.png";
import multicom from "../images/com/multicom.png";
import tradcom from "../images/com/tradcom.jpg";
import homecom from "../images/com/homecom.jpg";
import popcom from "../images/com/popcom.jpg";
import richardcom from "../images/com/richardcom.jpg";
import { faAccusoft } from "@fortawesome/free-brands-svg-icons";
// import logoaqs from "../images/logoaqsp.png";

const LeftMenu: React.FC = () => {
  const [menuShow, setmenuShow] = useState(true);
  const [comOr, setComOr] = useState(pichecom);
  const switchComOr = (): void => {
    setComOr(com3);
  };
  const switchComOrBack = (): void => {
    setComOr(pichecom);
  };
  const [comBronze, setComBronze] = useState(tradcom);
  const switchComBronze = (): void => {
    setComBronze(com5);
  };
  const switchComBronzeBack = (): void => {
    setComBronze(tradcom);
  };

  const [comstar1, setcomstar] = useState(hbcom);
  const switchcomstar1 = (): void => {
    setcomstar(com6);
  };
  const switchcomstar1Back = (): void => {
    setcomstar(hbcom);
  };

  const [comstar2, setcomstar2] = useState(rivgencom);
  const switchcomstar2 = (): void => {
    setcomstar2(com6);
  };
  const switchcomstar2Back = (): void => {
    setcomstar2(rivgencom);
  };

  const [comstar3, setcomstar3] = useState(graincom);
  const switchcomstar3 = (): void => {
    setcomstar3(com6);
  };
  const switchcomstar3Back = (): void => {
    setcomstar3(graincom);
  };

  const [comstar4, setcomstar4] = useState(multicom);
  const switchcomstar4 = (): void => {
    setcomstar4(com6);
  };
  const switchcomstar4Back = (): void => {
    setcomstar4(multicom);
  };
  const [comstar5, setcomstar5] = useState(homecom);
  const switchcomstar5 = (): void => {
    setcomstar5(com6);
  };
  const switchcomstar5Back = (): void => {
    setcomstar5(homecom);
  };

  const [comstar6, setcomstar6] = useState(popcom);
  const switchcomstar6 = (): void => {
    setcomstar6(com6);
  };
  const switchcomstar6Back = (): void => {
    setcomstar6(popcom);
  };

  const [comstar7, setcomstar7] = useState(richardcom);
  const switchcomstar7 = (): void => {
    setcomstar7(com6);
  };
  const switchcomstar7Back = (): void => {
    setcomstar7(richardcom);
  };

  const [comArgent, setComArgent] = useState(jbcom);
  const switchComArgent = (): void => {
    setComArgent(com4);
  };
  const switchComArgentBack = (): void => {
    setComArgent(jbcom);
  };

  const showMenu = (event: any): void => {
    const divMenu = document.getElementById("menu") as HTMLInputElement;

    if (menuShow) {
      divMenu.className = " invisible w-0 z-0";
      setmenuShow(false);
    } else {
      divMenu.className = " visible w-60 z-40";
      setmenuShow(true);
    }
  };

  const hideOnClick = (event: any): void => {
    const divMenu = document.getElementById("menu") as HTMLInputElement;
    if (window.innerWidth < 600) {
      setmenuShow(false);
      divMenu.className = " invisible w-0 z-0";
    }
  };
  return (
    <div>
      <div className="absolute m-2 z-50 rounded-xl font-josefin text-xl h-14 w-14">
        <img
          id="menuimg"
          onClick={showMenu}
          className=" rounded-lg bg-robins-egg-blue-700/50"
          src={menuxpand}
          alt="menu"
        />
      </div>
      <div id="menu" className="hidden sm:block z-40 w-60">
        <div className="bg-white/30 shrink-0 overflow-auto sm:w-52 md:w-52 lg:w-52 xl:block xl:w-52 2xl:w-52 p-0 md:p-5 lg:p-5 xl:p-5 2xl:p-5 ">
          <div onClick={hideOnClick} className="mt-8">
            <Link to="/accueil">
              <LeftMenuItem icon={faHome} name="accueil" />
            </Link>
            <Link to="/pools">
              <LeftMenuItem icon={faAccusoft} name="** pools **" />
            </Link>
            <Link to="/inscriptions">
              <LeftMenuItem icon={faHandHoldingUsd} name="Inscription 2023" />
            </Link>
            <Link to="/inscriptionsJeunes">
              <LeftMenuItem
                icon={faHandHoldingUsd}
                name="Inscription Jr(14-)"
              />
            </Link>
            <Link to="/equipes">
              <LeftMenuItem icon={faStore} name="Les équipes" />
            </Link>
            <Link to="/equipesJeunes">
              <LeftMenuItem icon={faStore} name="Les équipes Jr(14-) " />
            </Link>
            <Link to="/reglements">
              <LeftMenuItem icon={faClipboardCheck} name="Règlements" />
            </Link>
            <Link to="/contact">
              <LeftMenuItem icon={faTools} name="Contact" />
            </Link>
            <Link to="/commanditaires">
              <LeftMenuItem icon={faMoneyCheck} name="commanditaires" />
            </Link>
          </div>
          <div className="font-josefin text-xl mb-2 mt-10">Commanditaires</div>

          <Link to="/commanditaires">
            <div>
              <img className="w-auto rounded-lg mb-4" src={com1} alt="com1" />
              <img className="w-auto rounded-lg mb-4" src={com2} alt="com2" />
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchComOr}
                onMouseLeave={switchComOrBack}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comOr}
                  alt="comOr"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchComArgent}
                onMouseLeave={switchComArgentBack}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comArgent}
                  alt="com4"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchComBronze}
                onMouseLeave={switchComBronzeBack}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comBronze}
                  alt="com5"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar1}
                onMouseLeave={switchcomstar1Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar1}
                  alt="comStar1"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar2}
                onMouseLeave={switchcomstar2Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar2}
                  alt="comStar2"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar3}
                onMouseLeave={switchcomstar3Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar3}
                  alt="comStar3"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar4}
                onMouseLeave={switchcomstar4Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar4}
                  alt="comStar4"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar5}
                onMouseLeave={switchcomstar5Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar5}
                  alt="comStar5"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar6}
                onMouseLeave={switchcomstar6Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar6}
                  alt="comStar6"
                />
              </div>
              <div
                className="transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-40 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
                onMouseOver={switchcomstar7}
                onMouseLeave={switchcomstar7Back}
              >
                <img
                  className=" h-28 rounded-lg mb-4 "
                  src={comstar7}
                  alt="comStar7"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
