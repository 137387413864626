/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import entetep4 from "../images/entetep4.png";
import souqueur1 from "../images/souqueur1.png";
import souqueur2 from "../images/souqueur2.png";
import souqueur3 from "../images/souqueur3.png";
import souqueur4 from "../images/souqueur4.png";
import remplacant from "../images/remplacant.png";
import { equipe } from "../types/equipe";
import { inscriptionEquipeJeunes } from "../services/inscriptionEquipeService";

const InscriptionsJeunes: React.FC = () => {
  const test: any = {};
  const newTeamObjTemp: equipe = {
    ...test,
  };
  const [newTeam, setNewTeam] = useState<equipe>(newTeamObjTemp);

  const setInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTeamReas = { ...newTeam, [event.target.id]: event.target.value };
    setNewTeam(newTeamReas);
    console.log(newTeam);
  };

  const checkInputValidText = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ): void => {
    if (
      !event.target.validity.patternMismatch &&
      !event.target.validity.valueMissing
    ) {
      setInput(event);
    } else {
      alert(
        "Veuillez entrer seulement des nombres pour le poids et l'age - Que des lettres pour les Prénoms et Noms - Que des adresses Email Valides (mail@mailto.com)"
      );

      event.target.value = "";
      const newTeamReas = { ...newTeam, [event.target.id]: "" };
      setNewTeam(newTeamReas);
      // console.log(newTeam);
    }

    event.preventDefault();
  };

  const sendSub = (): void => {
    if (checkAllForm()) {
      if (
        confirm(
          "Bonjour les " +
            newTeam.nomEquipe +
            "! Votre inscription est presque en route, veuillez consulter vos courriels et payer vos droits d'insctiption(50$ / équipe JR.) par interac à info@puissance4.net. Svp, confirmez votre inscription en appuyant sur OK ! "
        )
      ) {
        inscriptionEquipeJeunes(newTeam);
        alert(
          "ATTENTION ! - Si vous ne recevez pas de courriels de confirmation dans les minutes qui suivent, VOUS N'ÊTES PAS INSCRIT ! - CONTACTEZ-NOUS : info@puissance4.net - Vérifiez vos pourriels."
        );
        window.open("https://www.puissance4.net");
      } else {
        // Do nothing!
        alert("Vérifiez vos informations et revalidez. Merci !");
      }
    } else {
      alert(
        "Vous devez remplir tous les champs pour au moins 5 tireurs. Tous les espaces blancs et options doivent être remplit pour les 4 premiers joueurs."
      );
    }
  };

  const checkAllForm = (): boolean => {
    let formAllGood = true;
    // formAllGood = newTeam.nomEquipe !== "undefined";

    if (newTeam.nomEquipe === undefined || newTeam.nomEquipe === "") {
      return false;
    }
    // if (newTeam.nomEquipe.length === undefined) {
    //   formAllGood = false;
    // }

    formAllGood = !!newTeam.prenom1;
    formAllGood = !!newTeam.age1;
    formAllGood = !!newTeam.email1;
    formAllGood = !!newTeam.nom1;
    formAllGood = !!newTeam.poids1;
    if (newTeam.experience1 === undefined) {
      formAllGood = false;
    }
    if (newTeam.genre1 === undefined) {
      formAllGood = false;
    }

    formAllGood = !!newTeam.prenom2;
    formAllGood = !!newTeam.age2;
    formAllGood = !!newTeam.email2;
    formAllGood = !!newTeam.nom2;
    formAllGood = !!newTeam.poids2;
    if (newTeam.experience2 === undefined) {
      formAllGood = false;
    }
    if (newTeam.genre2 === undefined) {
      formAllGood = false;
    }

    formAllGood = !!newTeam.prenom3;
    formAllGood = !!newTeam.age3;
    formAllGood = !!newTeam.email3;
    formAllGood = !!newTeam.nom3;
    formAllGood = !!newTeam.poids3;
    if (newTeam.experience3 === undefined) {
      formAllGood = false;
    }
    if (newTeam.genre3 === undefined) {
      formAllGood = false;
    }

    formAllGood = !!newTeam.prenom4;
    formAllGood = !!newTeam.age4;
    formAllGood = !!newTeam.email4;
    formAllGood = !!newTeam.nom4;
    formAllGood = !!newTeam.poids4;
    if (newTeam.experience4 === undefined) {
      formAllGood = false;
    }
    if (newTeam.genre4 === undefined) {
      formAllGood = false;
    }

    formAllGood = !!newTeam.prenom5;
    formAllGood = !!newTeam.age5;
    formAllGood = !!newTeam.email5;
    formAllGood = !!newTeam.nom5;
    formAllGood = !!newTeam.poids5;
    if (newTeam.experience5 === undefined) {
      formAllGood = false;
    }
    if (newTeam.genre5 === undefined) {
      formAllGood = false;
    }

    return formAllGood;
  };

  return (
    <div className="flex flex-col h-max border-4  m-4 rounded-3xl w-full object-contain ">
      <div className="z-0 absolute object-contain">
        <img className="  opacity-5 l" src={entetep4} alt="logo ULaval" />
      </div>
      <div className="flex flex-col items-center text-sm overflow-hidden justify-center border-2 rounded-2xl m-1 ml-10 mr-10 z-40 hover:bg-electric-violet-700/10 bg-robins-egg-blue-100 text-electric-violet-600 hover:text-electric-violet-600">
        <div className="flex flex-col  sm:flex-row items-center sm:text-2xl font-medium m-2">
          Enfants 14 ans et - (50$ / équipe)
        </div>
        <div className="flex flex-col sm:flex-row items-center">
          <div>
            <h2 className=" sm:text-2xl font-medium m-2">
              (Obligatoire)Nom équipe :
            </h2>
          </div>
          <form>
            <div>
              <input
                id={"nomEquipe"}
                className="border-electric-violet-100 w-4/5 h-10 text-2xl border-2 p-1 outline-none valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                name="teamName"
                type="text"
                defaultValue={newTeam.nomEquipe}
                placeholder={"Nom Équipe"}
                required
                pattern="[A-Za-z0-9' ']{1,32}"
                onChange={checkInputValidText}
              />
            </div>
          </form>
        </div>
      </div>
      {/* Carton -------------------------------- Tirreut 1 */}
      <div className="flex flex-col overflow-hidden sm:flex-row items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <div>
          <div className="z-0">
            <img className="scale-75" src={souqueur1} alt="logo ULaval" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-left text-2xl text-electric-violet-800 font-bold ">
            <h2>Tireur #1</h2>
            <h2>-Capitaine-</h2>
          </div>
          <div className="flex flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Prénom :</h2>
              </div>
              <div>
                <input
                  id={"prenom1"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="prenom1"
                  type="text"
                  defaultValue={""}
                  placeholder={"Prénom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">nom :</h2>
              </div>
              <div>
                <input
                  id={"nom1"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="nom1"
                  type="text"
                  defaultValue={""}
                  placeholder={"Nom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row   justify-left">
            <div className="flex flex-col sm:flex-row   w-5/12">
              <div>
                <h2 className="text-white m-2">email :</h2>
              </div>
              <div>
                <input
                  id={"email1"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="courriel1"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  defaultValue={""}
                  placeholder={"Courriel"}
                  onChange={setInput}
                  onBlur={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12 ">
              <div>
                <h2 className="text-white m-2">Age :</h2>
              </div>
              <div>
                <input
                  id={"age1"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="age1"
                  pattern="[0-9]*"
                  type="text"
                  defaultValue={""}
                  placeholder={"Age"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Poids anticipé :</h2>
              </div>
              <div>
                <input
                  id={"poids1"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="poids1"
                  type="text"
                  pattern="[0-9]*"
                  defaultValue={""}
                  placeholder={"Poids"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row ">
              <div>
                <h2 className="text-white m-2">Genre :</h2>
              </div>
              <div className="flex">
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-rosso hover:bg-rosso/30">
                  <h1>Homme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre1"
                      onChange={setInput}
                      type="radio"
                      value="Homme"
                      name="gender1"
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>Femme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre1"
                      type="radio"
                      value="Femme"
                      name="gender1"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-left w-full p-1">
            <div className="flex flex-col items-center sm:flex-row ">
              <div className="mr-2">
                <h2 className="text-white m-2">Expérience :</h2>
              </div>
              <div className="flex flex-col items-center sm:flex-row ">
                <div className="flex flex-col border-2 rounded-full p-2 mr-2 justify-center text-white border-electric-violet-100 border-x-rosso hover:bg-rosso/30">
                  <h1>Première compétition</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name="experience1"
                      id="experience1"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>1 à 3 compétition(s)</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="2"
                      name="experience1"
                      id="experience1"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-blue hover:bg-electric-violet-300/30">
                  <h1>4 et + compétitions</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="4"
                      name="experience1"
                      id="experience1"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carton -------------------------------- Tirreut 2 */}
      <div className="flex flex-col overflow-hidden sm:flex-row items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <div>
          <div className="z-0">
            <img className="scale-75" src={souqueur2} alt="logo ULaval" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-left text-2xl text-electric-violet-800 font-bold ">
            <h2>Tireur #2</h2>
          </div>
          <div className="flex flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Prénom :</h2>
              </div>
              <div>
                <input
                  id={"prenom2"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="prenom2"
                  type="text"
                  defaultValue={""}
                  placeholder={"Prénom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">nom :</h2>
              </div>
              <div>
                <input
                  id={"nom2"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="nom2"
                  type="text"
                  defaultValue={""}
                  placeholder={"Nom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row   justify-left">
            <div className="flex flex-col sm:flex-row   w-5/12">
              <div>
                <h2 className="text-white m-2">email :</h2>
              </div>
              <div>
                <input
                  id={"email2"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="courriel2"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  defaultValue={""}
                  placeholder={"Courriel"}
                  onChange={setInput}
                  onBlur={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12 ">
              <div>
                <h2 className="text-white m-2">Age :</h2>
              </div>
              <div>
                <input
                  id={"age2"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="age2"
                  pattern="[0-9]*"
                  type="text"
                  defaultValue={""}
                  placeholder={"Age"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Poids anticipé :</h2>
              </div>
              <div>
                <input
                  id={"poids2"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="poids2"
                  type="text"
                  pattern="[0-9]*"
                  defaultValue={""}
                  placeholder={"Poids"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row ">
              <div>
                <h2 className="text-white m-2">Genre :</h2>
              </div>
              <div className="flex">
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-rosso hover:bg-rosso/30">
                  <h1>Homme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre2"
                      onChange={setInput}
                      type="radio"
                      value="Homme"
                      name="gender2"
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>Femme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre2"
                      type="radio"
                      value="Femme"
                      name="gender2"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-left w-full p-1">
            <div className="flex flex-col items-center sm:flex-row ">
              <div className="mr-2">
                <h2 className="text-white m-2">Expérience :</h2>
              </div>
              <div className="flex flex-col items-center sm:flex-row ">
                <div className="flex flex-col border-2 rounded-full p-2 mr-2 justify-center text-white border-electric-violet-100 border-x-rosso hover:bg-rosso/30">
                  <h1>Première compétition</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name="experience2"
                      id="experience2"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>1 à 3 compétition(s)</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="2"
                      name="experience2"
                      id="experience2"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-blue hover:bg-electric-violet-300/30">
                  <h1>4 et + compétitions</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="4"
                      name="experience2"
                      id="experience2"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carton -------------------------------- Tirreut 3 */}
      <div className="flex flex-col overflow-hidden sm:flex-row items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <div>
          <div className="z-0">
            <img className="scale-75" src={souqueur3} alt="logo ULaval" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-left text-2xl text-electric-violet-800 font-bold ">
            <h2>Tireur #3</h2>
          </div>
          <div className="flex flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Prénom :</h2>
              </div>
              <div>
                <input
                  id={"prenom3"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="prenom3"
                  type="text"
                  defaultValue={""}
                  placeholder={"Prénom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">nom :</h2>
              </div>
              <div>
                <input
                  id={"nom3"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="nom3"
                  type="text"
                  defaultValue={""}
                  placeholder={"Nom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row   justify-left">
            <div className="flex flex-col sm:flex-row   w-5/12">
              <div>
                <h2 className="text-white m-2">email :</h2>
              </div>
              <div>
                <input
                  id={"email3"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="courriel3"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  defaultValue={""}
                  placeholder={"Courriel"}
                  onChange={setInput}
                  onBlur={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12 ">
              <div>
                <h2 className="text-white m-2">Age :</h2>
              </div>
              <div>
                <input
                  id={"age3"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="age3"
                  pattern="[0-9]*"
                  type="text"
                  defaultValue={""}
                  placeholder={"Age"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Poids anticipé :</h2>
              </div>
              <div>
                <input
                  id={"poids3"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="poids3"
                  type="text"
                  pattern="[0-9]*"
                  defaultValue={""}
                  placeholder={"Poids"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row ">
              <div>
                <h2 className="text-white m-2">Genre :</h2>
              </div>
              <div className="flex">
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-rosso hover:bg-rosso/30">
                  <h1>Homme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre3"
                      onChange={setInput}
                      type="radio"
                      value="Homme"
                      name="gender3"
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>Femme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre3"
                      type="radio"
                      value="Femme"
                      name="gender3"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-left w-full p-1">
            <div className="flex flex-col items-center sm:flex-row ">
              <div className="mr-2">
                <h2 className="text-white m-2">Expérience :</h2>
              </div>
              <div className="flex flex-col items-center sm:flex-row ">
                <div className="flex flex-col border-2 rounded-full p-2 mr-2 justify-center text-white border-electric-violet-100 border-x-rosso hover:bg-rosso/30">
                  <h1>Première compétition</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name="experience3"
                      id="experience3"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>1 à 3 compétition(s)</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="2"
                      name="experience3"
                      id="experience3"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-blue hover:bg-electric-violet-300/30">
                  <h1>4 et + compétitions</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="4"
                      name="experience3"
                      id="experience3"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carton -------------------------------- Tirreut 4 */}
      <div className="flex flex-col overflow-hidden sm:flex-row items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <div>
          <div className="z-0">
            <img className="scale-75" src={souqueur4} alt="logo ULaval" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-left text-2xl text-electric-violet-800 font-bold ">
            <h2>Tireur #4</h2>
          </div>
          <div className="flex flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Prénom :</h2>
              </div>
              <div>
                <input
                  id={"prenom4"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="prenom4"
                  type="text"
                  defaultValue={""}
                  placeholder={"Prénom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">nom :</h2>
              </div>
              <div>
                <input
                  id={"nom4"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="nom4"
                  type="text"
                  defaultValue={""}
                  placeholder={"Nom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row   justify-left">
            <div className="flex flex-col sm:flex-row   w-5/12">
              <div>
                <h2 className="text-white m-2">email :</h2>
              </div>
              <div>
                <input
                  id={"email4"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="courriel4"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  defaultValue={""}
                  placeholder={"Courriel"}
                  onChange={setInput}
                  onBlur={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12 ">
              <div>
                <h2 className="text-white m-2">Age :</h2>
              </div>
              <div>
                <input
                  id={"age4"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="age4"
                  pattern="[0-9]*"
                  type="text"
                  defaultValue={""}
                  placeholder={"Age"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Poids anticipé :</h2>
              </div>
              <div>
                <input
                  id={"poids4"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="poids4"
                  type="text"
                  pattern="[0-9]*"
                  defaultValue={""}
                  placeholder={"Poids"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row ">
              <div>
                <h2 className="text-white m-2">Genre :</h2>
              </div>
              <div className="flex">
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-rosso hover:bg-rosso/30">
                  <h1>Homme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre4"
                      onChange={setInput}
                      type="radio"
                      value="Homme"
                      name="gender4"
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>Femme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre4"
                      type="radio"
                      value="Femme"
                      name="gender4"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-left w-full p-1">
            <div className="flex flex-col items-center sm:flex-row ">
              <div className="mr-2">
                <h2 className="text-white m-2">Expérience :</h2>
              </div>
              <div className="flex flex-col items-center sm:flex-row ">
                <div className="flex flex-col border-2 rounded-full p-2 mr-2 justify-center text-white border-electric-violet-100 border-x-rosso hover:bg-rosso/30">
                  <h1>Première compétition</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name="experience4"
                      id="experience4"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>1 à 3 compétition(s)</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="2"
                      name="experience4"
                      id="experience4"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-blue hover:bg-electric-violet-300/30">
                  <h1>4 et + compétitions</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="4"
                      name="experience4"
                      id="experience4"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carton -------------------------------- joueur 5 */}
      <div className="flex flex-col overflow-hidden sm:flex-row items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <div>
          <div className="z-0">
            <img className="scale-75" src={remplacant} alt="logo ULaval" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-left text-2xl text-electric-violet-800 font-bold ">
            <h2>Tireur #5</h2>
          </div>
          <div className="flex flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Prénom :</h2>
              </div>
              <div>
                <input
                  id={"prenom5"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="prenom5"
                  type="text"
                  defaultValue={""}
                  placeholder={"Prénom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">nom :</h2>
              </div>
              <div>
                <input
                  id={"nom5"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  pattern="[A-Za-z' ']{1,32}"
                  name="nom5"
                  type="text"
                  defaultValue={""}
                  placeholder={"Nom"}
                  onChange={checkInputValidText}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row   justify-left">
            <div className="flex flex-col sm:flex-row   w-5/12">
              <div>
                <h2 className="text-white m-2">email :</h2>
              </div>
              <div>
                <input
                  id={"email5"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="courriel5"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  defaultValue={""}
                  placeholder={"Courriel"}
                  onChange={setInput}
                  onBlur={checkInputValidText}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row  w-5/12 ">
              <div>
                <h2 className="text-white m-2">Age :</h2>
              </div>
              <div>
                <input
                  id={"age5"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="age5"
                  pattern="[0-9]*"
                  type="text"
                  defaultValue={""}
                  placeholder={"Age"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col sm:flex-row  justify-left">
            <div className="flex flex-col sm:flex-row  w-5/12">
              <div>
                <h2 className="text-white m-2">Poids anticipé :</h2>
              </div>
              <div>
                <input
                  id={"poids5"}
                  className="border-electric-violet-100 border-2 p-1 outline-none h-6 w-36 m-2 rounded-xl valid:bg-electric-violet-200/10 valid:border-0 valid:text-white bg-whites"
                  name="poids5"
                  type="text"
                  pattern="[0-9]*"
                  defaultValue={""}
                  placeholder={"Poids"}
                  required
                  onChange={checkInputValidText}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row ">
              <div>
                <h2 className="text-white m-2">Genre :</h2>
              </div>
              <div className="flex">
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-rosso hover:bg-rosso/30">
                  <h1>Homme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre5"
                      onChange={setInput}
                      type="radio"
                      value="Homme"
                      name="gender5"
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 justify-center text-white border-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>Femme</h1>
                  <div className="flex justify-center">
                    <input
                      id="genre5"
                      type="radio"
                      value="Femme"
                      name="gender5"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-left w-full p-1">
            <div className="flex flex-col items-center sm:flex-row ">
              <div className="mr-2">
                <h2 className="text-white m-2">Expérience :</h2>
              </div>
              <div className="flex flex-col items-center sm:flex-row ">
                <div className="flex flex-col border-2 rounded-full p-2 mr-2 justify-center text-white border-electric-violet-100 border-x-rosso hover:bg-rosso/30">
                  <h1>Première compétition</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="1"
                      name="experience5"
                      id="experience5"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-electric-violet-500 hover:bg-electric-violet-500/30">
                  <h1>1 à 3 compétition(s)</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="2"
                      name="experience5"
                      id="experience5"
                      onChange={setInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col border-2 rounded-full p-2 mr-2  justify-center text-white border-electric-violet-100 border-x-blue hover:bg-electric-violet-300/30">
                  <h1>4 et + compétitions</h1>
                  <div className="flex justify-center">
                    <input
                      type="radio"
                      value="4"
                      name="experience5"
                      id="experience5"
                      onChange={setInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BOUTON GO */}
      <div className="flex  items-center justify-center p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
        <button
          onClick={sendSub}
          className="bg-electric-violet-600 hover:bg-electric-violet-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
        >
          Valider votre inscription !
        </button>
      </div>
    </div>
  );
};

export default InscriptionsJeunes;
