/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import entetep4 from "../images/entetep4.png";
import com1 from "../images/com/1.jpg";
import com2 from "../images/com/2.jpg";
import com3 from "../images/com/3.jpg";
import com4 from "../images/com/4.jpg";
import com5 from "../images/com/5.jpg";
import com6 from "../images/com/6.jpg";
import hbcom from "../images/com/hbcom.png";
import jbcom from "../images/com/jbcom.png";
import pichecom from "../images/com/pichecom.png";
import graincom from "../images/com/graincom.png";
import rivgencom from "../images/com/rivgencom.png";
import multicom from "../images/com/multicom.png";
import tradcom from "../images/com/tradcom.jpg";
import homecom from "../images/com/homecom.jpg";
import popcom from "../images/com/popcom.jpg";
import richardcom from "../images/com/richardcom.jpg";
import logoaqs from "../images/logoaqsp.png";
import { equipe } from "../types/equipe";
import { inscriptionEquipe } from "../services/inscriptionEquipeService";

const Commanditaires: React.FC = () => {
  const [comOr, setComOr] = useState(pichecom);
  const switchComOr = (): void => {
    setComOr(com3);
  };
  const switchComOrBack = (): void => {
    setComOr(pichecom);
  };
  const [comBronze, setComBronze] = useState(tradcom);
  const switchComBronze = (): void => {
    setComBronze(com5);
  };
  const switchComBronzeBack = (): void => {
    setComBronze(tradcom);
  };

  const [comstar1, setcomstar] = useState(hbcom);
  const switchcomstar1 = (): void => {
    setcomstar(com6);
  };
  const switchcomstar1Back = (): void => {
    setcomstar(hbcom);
  };

  const [comstar2, setcomstar2] = useState(rivgencom);
  const switchcomstar2 = (): void => {
    setcomstar2(com6);
  };
  const switchcomstar2Back = (): void => {
    setcomstar2(rivgencom);
  };

  const [comstar3, setcomstar3] = useState(graincom);
  const switchcomstar3 = (): void => {
    setcomstar3(com6);
  };
  const switchcomstar3Back = (): void => {
    setcomstar3(graincom);
  };

  const [comstar4, setcomstar4] = useState(multicom);
  const switchcomstar4 = (): void => {
    setcomstar4(com6);
  };
  const switchcomstar4Back = (): void => {
    setcomstar4(multicom);
  };

  const [comstar5, setcomstar5] = useState(popcom);
  const switchcomstar5 = (): void => {
    setcomstar5(com6);
  };
  const switchcomstar5Back = (): void => {
    setcomstar5(popcom);
  };

  const [comstar6, setcomstar6] = useState(homecom);
  const switchcomstar6 = (): void => {
    setcomstar6(com6);
  };
  const switchcomstar6Back = (): void => {
    setcomstar6(homecom);
  };

  const [comstar7, setcomstar7] = useState(richardcom);
  const switchcomstar7 = (): void => {
    setcomstar7(com6);
  };
  const switchcomstar7Back = (): void => {
    setcomstar7(richardcom);
  };

  const [comArgent, setComArgent] = useState(jbcom);
  const switchComArgent = (): void => {
    setComArgent(com4);
  };
  const switchComArgentBack = (): void => {
    setComArgent(jbcom);
  };

  return (
    <div className="flex flex-col h-max border-4  m-4 rounded-3xl w-full border-electric-violet-400 object-contain ">
      <div className="flex justify-center border-2 rounded-2xl m-1 ml-10 mr-10 z-50 hover:bg-electric-violet-700/10 bg-robins-egg-blue-300 text-electric-violet-700 hover:text-electric-violet-600">
        <div className="flex items-center">
          <div>
            <h2 className=" text-2xl font-medium m-2">
              Merci à nos précieux commanditaires !
            </h2>
          </div>
        </div>
      </div>
      <div className="hidden sm:block ">
        <div className="flex ">
          <div className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
            <img className=" rounded-lg mb-4 h-60" src={com1} alt="com1" />
          </div>
          <div className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
            <img className=" rounded-lg mb-4 h-60" src={com2} alt="com2" />
          </div>
          <div
            onClick={() => window.open("https://www.picheinc.com/fr", "_blank")}
            onMouseOver={switchComOr}
            onMouseLeave={switchComOrBack}
            className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-100/80 transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-y-40 hover:-translate-x-96 hover:scale-150"
          >
            <img className=" rounded-lg mb-4 h-60" src={comOr} alt="pichecom" />
          </div>
        </div>

        <div className="flex ">
          <div
            onClick={() => window.open("http://jbexcavation.site/", "_blank")}
            onMouseOver={switchComArgent}
            onMouseLeave={switchComArgentBack}
            className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-100/80 transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:-translate-y-40 hover:translate-x-96 hover:scale-150"
          >
            <img className=" rounded-lg mb-4 h-60" src={comArgent} alt="com4" />
          </div>
          <div
            onClick={() =>
              window.open(
                "https://www.google.com/search?q=march%C3%A9+tradition+daveluyville&source=hp&ei=vUBIY-YOnZ2m1A-7xI7oCw&iflsig=AJiK0e8AAAAAY0hOzQXKcWZvfIUv4kfnDveiZP_C4DHB&gs_ssp=eJzj4tZP1zcsqUg2y0syMWC0UjWoMElONk8zMDMxM0hMMUtMTbMyqEizsDQ1skgzNDI2MEy0tEj2kstNLErOOLxSoaQoMSWzJDM_TyElsSw1p7SyLDMnJxUAKgwbeA&oq=march%C3%A9+tradition+dave&gs_lcp=Cgdnd3Mtd2l6EAMYADILCC4QgAQQxwEQrwEyBggAEBYQHjICCCY6CwgAEIAEELEDEIMBOggILhCxAxCDAToHCAAQgAQQAzoRCC4QgAQQsQMQgwEQxwEQ0QM6CwguEIAEELEDEIMBOggIABCxAxCDAToICAAQgAQQsQM6BQgAEIAEOgsIABCABBCxAxDJAzoOCC4QgAQQsQMQxwEQ0QM6DgguEIAEELEDEMcBEK8BOgsILhCABBDHARDRAzoJCAAQFhAeEMkDOggIABAWEB4QCjoNCC4QgAQQxwEQrwEQEzoICAAQFhAeEBNQAFjFGmDFI2gAcAB4AYABlAGIAYYQkgEEMTIuOZgBAKABAQ&sclient=gws-wiz",
                "_blank"
              )
            }
            onMouseOver={switchComBronze}
            onMouseLeave={switchComBronzeBack}
            className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-100/80 transition duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:-translate-y-40 hover:translate-x-96 hover:scale-150"
          >
            <img className=" rounded-lg mb-4 h-60" src={comBronze} alt="com4" />
          </div>
          <div className="flex items-center justify-left p-4 ml-10 mr-10 z-10 border-2 rounded-2xl m-1 hover:bg-electric-violet-700/10">
            <img className=" rounded-lg mb-4 h-60" src={com6} alt="com6" />
          </div>
        </div>
      </div>

      {/* list */}
      <div className="flex  flex-col sm:flex-row justify-between ">
        <div
          className="transition scale-100 m-4 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-150"
          onMouseOver={switchComOr}
          onMouseLeave={switchComOrBack}
        >
          <img className=" rounded-lg mb-4 h-60" src={comOr} alt="comOr" />
        </div>

        <div
          className="transition scale-100 m-4 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-150"
          onMouseOver={switchComArgent}
          onMouseLeave={switchComArgentBack}
        >
          <img className=" rounded-lg mb-4 h-60" src={comArgent} alt="com4" />
        </div>

        <div
          className="transition scale-100 m-4 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-150"
          onMouseOver={switchComBronze}
          onMouseLeave={switchComBronzeBack}
        >
          <img className=" rounded-lg mb-4 h-60" src={comBronze} alt="com4" />
        </div>
      </div>
      <div className="flex  justify-between">
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar1}
          onMouseLeave={switchcomstar1Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar1} alt="com4" />
        </div>
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar2}
          onMouseLeave={switchcomstar2Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar2} alt="com4" />
        </div>
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar3}
          onMouseLeave={switchcomstar3Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar3} alt="com4" />
        </div>
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar4}
          onMouseLeave={switchcomstar4Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar4} alt="com4" />
        </div>
      </div>
      <div className="flex  justify-between">
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar5}
          onMouseLeave={switchcomstar5Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar5} alt="com4" />
        </div>
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar6}
          onMouseLeave={switchcomstar6Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar6} alt="com4" />
        </div>
        <div
          className="transition scale-50 duration-1000 ease-in-out delay-150 bg-blue-500 hover:z-50 hover:translate-x-3 hover:-translate-y-3 hover:scale-110"
          onMouseOver={switchcomstar7}
          onMouseLeave={switchcomstar7Back}
        >
          <img className=" rounded-lg mb-4 h-60" src={comstar7} alt="com4" />
        </div>
      </div>
    </div>
  );
};

export default Commanditaires;
