/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import stag from "../images/stag.png";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import p4affiche from "../images/p4affichesmall.png";

const Accueil: React.FC = () => {
  return (
    <div className="flex flex-row justify-center rounded-2xl opacity-100 w-full">
      <div className="text-6xl">
        <div className="w-auto">
          <img src={p4affiche} alt="logo ULaval" />
        </div>
      </div>
    </div>
  );
};

export default Accueil;
