/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import pools from "../images/pools.png";

const Pools: React.FC = () => {
  return (
    <div className="flex flex-row justify-center rounded-2xl opacity-100 w-full">
      <div className="text-6xl">
        <div className="w-auto">
          <img src={pools} alt="Pools" />
        </div>
      </div>
    </div>
  );
};

export default Pools;
