/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// eslint-disable-next-line no-unused-vars
import Home from "./views/Home";
import LeftMenu from "./components/LeftMenu";
// import EquipeList from "./components/EquipeList";
import React, { useEffect, useRef, useState } from "react";
import Accueil from "./components/Accueil";
import Inscriptions from "./components/Inscriptions";
import Commanditaires from "./components/Commanditaires";
import Reglements from "./components/Reglements";
import Equipe from "./components/Equipe";
import EquipeList from "./components/EquipeList";
import Contact from "./components/Contact";
import InscriptionsJeunes from "./components/InscriptionsJeunes";
import EquipeJeunesList from "./components/EquipeJeuneList";
import videoprep from "./videos/videoprep.mp4";
import Pools from "./components/pools";

const App: React.FC = () => {
  const [login, setLogin] = useState<string>("false");

  useEffect(() => {
    if (
      localStorage.getItem("aqsToken") ===
        "eyrJhbGciOiJIUzssI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMTY5NWFjOThjOWMzOGE5NGNkM2I3OSIsImlhdCI6MTY0NTY1OTY3MiwiZXhwIjoxNjQ2NjU5NjcyfQ.Xmfciv19JUIPzdlxbcYp4fft2EV7STKHnI7vODuPPUc" &&
      localStorage.getItem("aqsLogged") === "true"
    ) {
      setLogin(localStorage.getItem("aqsLogged") as string);
    }
  });

  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    addEventListener("mousemove", () => {
      playAfterXSec(0);
    });



  }, []);

  const playAfterXSec = async (secondsToPause: number) => {
    setTimeout(async () => {
      await refVideo.current?.play();
    }, secondsToPause * 1000);


  };

  const hideOnClick = (event: any): void => {
    const divMenu = document.getElementById("viddiv") as HTMLInputElement;
    divMenu.remove();
  };

  return (
    <Router>
      <div className="flex  flex-col h-full bg-gradient-to-b from-robins-egg-blue-100 to-gray ">
        <div>
          <div
            id="viddiv"
            onClick={hideOnClick}
            className="z-50 flex flex-col absolute bottom-0 right-0 "
          >
            <div
              onClick={hideOnClick}
              className="flex border-2 text-2xl rounded-xl w-min border-robins-egg-blue-800"
            >
              <h1>X</h1>
            </div>
            <div className="flex -mt-2">
              <video
                ref={refVideo}
                controls={true}
                src={videoprep}
                loop={true}
                muted={true}
                autoPlay={false}
                width="600"
                height="300"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start Main-content h-full w-full overflow-auto ">
          <div>
            <LeftMenu />
          </div>
          <div className="w-full flex ">
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/pools" element={<Pools />} />
              <Route path="/inscriptions" element={<Inscriptions />} />
              <Route
                path="/inscriptionsJeunes"
                element={<InscriptionsJeunes />}
              />
              <Route path="/equipes" element={<EquipeList />} />
              <Route path="/equipesJeunes" element={<EquipeJeunesList />} />
              <Route path="/commanditaires" element={<Commanditaires />} />
              <Route path="/reglements" element={<Reglements />} />
              <Route path="/contact" element={<Contact />} />
              {/* <Route path="/equipes" element={<EquipeList />} /> */}
            </Routes>
          </div>
        </div>
        {/* 
        <div className="flex pt-14">
          <div className="flex">
            
            
          </div>
        </div> */}
      </div>
    </Router>
  );
};

export default App;
